#burgerMenuContainer {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.904);
  backdrop-filter: blur(2px);
  z-index: 10;
  position: fixed;
  transition-duration: 0.5s;
}

#burgerMenuColumn {
  width: 100%;
  height: 100vh;
  justify-content: center;
  margin: auto 0px;
}

#burgerMenuItemBox {
  margin: 0px 0px 77px 0px;
}

.burgerMenuOpen {
  margin: 0px 0px 0px 0%;
}

.burgerMenuClose {
  margin: 0px 0px 0px -100%;
}

.burgerMenuItem {
  color: #538d72;
  font-size: 36px;
  margin: 36px 0px;
}
