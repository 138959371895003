#singleBlogContainer {
  width: 100%;
}

#singleBlogWrapper {
  width: 75%;
  max-width: 1000px;
  margin: 75px 0px 150px 0px;
}

#singleBlogDate {
  font-weight: 300;
}

#singleBlogTitle {
  font-weight: 400;
  font-size: 45px;
  margin: 30px 0px 50px 0px;
}

.singleBlogBody {
  line-height: 30px;
}

@media (max-width: 900px) {
  #singleBlogWrapper {
    margin: 70px 0px 125px 0px;
  }
}

@media (max-width: 600px) {
  #singleBlogWrapper {
    margin: 40px 0px 75px 0px;
  }
}
