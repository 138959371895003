#navBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 0px;
  background-color: rgb(214, 231, 223);
  z-index: 100;
  transition-duration: 3s;
}

.navBarFixed {
  position: fixed;
}

#danaKenney {
  font-size: 23px;
  color: #538d72;
  margin: 0px;
  font-weight: 500;
  margin: 0px 0px 0px 33px;
}

#navMiddle {
  display: block;
}

.navMiddleItem {
  margin: 0px 10px;
  font-size: 18px;
  font-weight: 400;
}

#contactButton {
  border: none;
  outline: none;
  background-color: #538d72;
  color: white;
  font-size: 13px;
  padding: 10px 18px;
  cursor: pointer;
  margin: 0px 33px 0px 0px;
}

#contactButton:hover {
  background-color: #7fa895;
}

#burgerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 28px;
  width: 28px;
  margin: 0px 33px 0px 0px;
  cursor: pointer;
  display: none;
}

#burgerContainer:hover {
  transform: scale(1.05);
}

.burgerClose {
  width: 28px;
  height: 3px;
  background-color: #538d72;
  border-radius: 3px;
  cursor: pointer;
  transition-duration: 0.5s;
  display: none;
}

.burgerClose::after {
  position: absolute;
  content: '';
  width: 28px;
  height: 3px;
  background-color: #538d72;
  transform: rotate(90deg);
  cursor: pointer;
  transition-duration: 0.5s;
}

.burgerOpen {
  width: 28px;
  height: 3px;
  background-color: #538d72;
  transform: rotate(45deg);
  border-radius: 3px;
  cursor: pointer;
  transition-duration: 0.5s;
  display: none;
}

.burgerOpen::after {
  position: absolute;
  content: '';
  width: 28px;
  height: 3px;
  background-color: #538d72;
  transform: rotate(90deg);
  cursor: pointer;
  transition-duration: 0.5s;
}

@media (max-width: 600px) {
  #burgerContainer {
    display: flex;
  }

  .burgerClose {
    display: block;
  }

  .burgerOpen {
    display: block;
  }

  #navMiddle {
    display: none;
  }

  #contactButton {
    display: none;
  }
}
