#box5 {
  width: 100%;
  height: 40vh;
  min-height: 325px;
  background-color: rgb(214, 231, 223);
  justify-content: center;
}

#box6 {
  min-height: 115px;
}

#talk {
  font-weight: 400;
}

#consultation2 {
  font-style: italic;
  font-weight: 400;
  text-align: center;
  margin: 0px 15px;
}

#consultationButton {
  border: none;
  outline: none;
  background-color: #538d72;
  color: white;
  font-size: 10px;
  padding: 16px 28px;
  cursor: pointer;
  font-size: 13px;
  margin: 30px 0px 0px 0px;
}

#consultationButton:hover {
  background-color: #7fa895;
}

#box6 {
  background-color: white;
  width: 100%;
  height: 15vh;
  justify-content: center;
}

#footerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#footer {
  display: flex;
  justify-content: space-between;
  width: 60%;
  min-width: 250px;
  /* margin: 0px 15px; */
}

.footerIcon {
  margin: 16px 0px 0px 0px;
  height: 30px;
  width: auto;
  cursor: pointer;
}

.footerIcon:hover {
  transform: scale(1.05);
}

#creditContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#credit {
  font-size: 11px;
  color: #538d72;
  margin: 0px 0px 12px 0px;
}

@media (max-width: 800px) {
  #footer {
    width: 65%;
  }
}

@media (max-width: 700px) {
  #footer {
    width: 75%;
  }
}

@media (max-width: 600px) {
  #footer {
    width: 85%;
  }
}
