#aboutContainer {
  width: 100%;
}

#aboutBox1 {
  width: 100%;
  height: 60vh;
  min-height: 350px;
  background-color: rgb(214, 231, 223);
  justify-content: center;
}

#aboutTitle {
  color: #538d72;
  font-weight: 400;
  font-size: 60px;
}

#aboutBox2 {
  width: 100%;
  min-height: 90vh;
  justify-content: center;
}

#aboutDescriptionContainer {
  width: 70%;
  max-width: 1035px;
  padding: 50px 0px;
}

#aboutDescription {
  font-size: 28px;
  line-height: 45px;
}

#danaPhoto2 {
  margin: 0px 0px 75px 0px;
  width: 90%;
  height: auto !important;
  min-width: 500px;
  max-width: 1200px;
}

#photo2Container {
  width: 100%;
  overflow: hidden;
}

.aboutLink {
  color: #538d72;
}

#featureLink {
  color: #538d72;
  font-weight: bold;
}

@media (max-width: 800px) {
  #aboutDescription {
    font-size: 25px;
    line-height: 40px;
  }
}

@media (max-width: 450px) {
  #aboutDescription {
    font-size: 22px;
    line-height: 35px;
  }
}
