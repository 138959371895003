#playlistSubmissionContainer {
  width: 100%;
}

#playlistBox1 {
  width: 100%;
  height: 60vh;
  background-color: rgb(214, 231, 223);
  justify-content: center;
}

#playlistPageTitle {
  color: #538d72;
  font-weight: 400;
  font-size: 44px;
  text-align: center;
  margin: 30px 20px;
}

#playlistBanner {
  width: 70%;
  max-width: 730px;
  height: auto;
  margin: 150px 0px 0px 0px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#playlistEmbed {
  width: 80%;
  max-width: 1000px;
  height: 750px;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#playlistEmbedWrapper {
  width: 100%;
  margin: 40px 0px 150px 0px;
}

@media (max-width: 600px) {
  #playlistBanner {
    margin: 100px 0px 0px 0px;
  }
  #playlistEmbedWrapper {
    width: 100%;
    margin: 40px 0px 100px 0px;
  }
}

@media (max-width: 500px) {
  #playlistBanner {
    margin: 75px 0px 0px 0px;
  }
  #playlistEmbedWrapper {
    width: 100%;
    margin: 40px 0px 75px 0px;
  }
}
