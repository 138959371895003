#up {
  position: fixed;
  width: 32px;
  height: auto;
  z-index: 9;
  bottom: 0;
  right: 0;
  margin: 0px 18px 18px 0px;
  transition-duration: 0.5s;
}

#up:hover {
  transform: scale(1.05);
}

.upHidden {
  opacity: 0;
}

.upShow {
  opacity: 0.5;
  cursor: pointer;
}
