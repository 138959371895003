#homePageContainer {
  width: 100%;
}

#box1 {
  width: 100%;
  height: 65vh;
  min-height: 600px;
  background-color: rgb(214, 231, 223);
  justify-content: center;
}

#box2 {
  background-color: white;
  width: 100%;
  height: 30vh;
  min-height: 500px;
  justify-content: center;
}

#sloganContainer {
  width: 45vw;
  margin: 0px 0px 0px 0px;
  max-width: 850px;
}

#slogan {
  color: #538d72;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  margin: 0px;
}

#consultation1 {
  margin: 32px 0px;
  color: #538d72;
  font-style: italic;
  text-decoration: underline;
  font-size: 18px;
}

#quote1Container {
  width: 50vw;
  max-width: 800px;
}

#quote1 {
  margin: 0;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
}

#mainPhotoContainer {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#mainPhoto {
  width: 100%;
  height: auto !important;
  min-width: 500px;
  max-width: 1200px;
}

#box3 {
  background-color: rgb(214, 231, 223);
  width: 100%;
  min-height: 80vh;
  justify-content: space-evenly;
}

#services {
  margin: 0px;
  color: #538d72;
  font-weight: 500;
}

#servicesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1195px;
}

.serviceBlock {
  width: 200px;
  margin: 50px;
}

.serviceBlockTitle {
  color: #538d72;
  font-style: italic;
}

.serviceDescription {
  text-align: center;
}

#learnMoreButton {
  border: none;
  outline: none;
  background-color: #538d72;
  color: white;
  font-size: 10px;
  padding: 14px 28px;
  cursor: pointer;
  font-size: 15px;
}

#learnMoreButton:hover {
  background-color: #7fa895;
}

#box4 {
  width: 100%;
  min-height: 65vh;
  background-color: white;
  justify-content: center;
}

#playlistContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 36px 0px;
}

#playlistDescriptionContainer {
  width: 36%;
  height: 420px;
  background-color: rgb(214, 231, 223);
  justify-content: center;
  text-align: center;
  max-width: 500px;
  min-width: 225px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#playlistDescription {
  font-weight: 400;
  margin: 0px 30px;
  line-height: 35px;
  font-size: 20px;
}

#spotifyEmbed {
  width: 300px;
  height: 380px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#publicistNote {
  font-style: italic;
  font-size: 12px;
}

@media (max-width: 925px) {
  .serviceBlock {
    margin: 30px;
  }
}

@media (max-width: 794px) {
  #services {
    margin: 50px 0px 25px 0px;
  }
  #learnMoreButton {
    margin: 20px 0px 75px 0px;
  }
}

@media (max-width: 750px) {
  #playlistContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  #playlistDescriptionContainer {
    margin: 100px 50px 0px 0px;
  }
  #spotifyEmbed {
    margin: 0px 0px 100px 50px;
  }
  #playlistDescription {
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  #playlistDescription {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  #sloganContainer {
    width: 50vw;
  }
  #box2 {
    height: 45vh;
  }
  #quote1Container {
    width: 55vw;
  }
}

@media (max-width: 534px) {
  #services {
    margin: 75px 0px 50px 0px;
  }
  #learnMoreButton {
    margin: 50px 0px 100px 0px;
    font-size: 10px;
    padding: 14px 28px;
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  #spotifyEmbed {
    width: 270px;
    height: 345px;
  }
}

@media (max-width: 420px) {
  #sloganContainer {
    width: 55vw;
  }
  #box2 {
    height: 55vh;
  }
  #quote1Container {
    width: 60vw;
  }
}

@media (max-width: 400px) {
  #playlistDescriptionContainer {
    margin: 100px 0px 0px 0px;
  }
  #spotifyEmbed {
    margin: 0px 0px 100px 0px;
  }
}

@media (max-width: 350px) {
  #quote1 {
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
  }
  #quote1Container {
    width: 70vw;
  }
}

@media (max-height: 1125px) and (min-width: 795px) {
  #box3 {
    padding: 50px 0px;
  }
}
