#blogContainer {
  width: 100%;
}

#blogBox1 {
  width: 100%;
  height: 30vh;
  min-height: 240px;
  background-color: white;
  justify-content: center;
}

#blogTitle {
  color: #538d72;
  font-weight: 400;
  font-size: 50px;
}

#blogBox2 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.singleBlog {
  width: 350px;
  margin: 125px 50px;
}

.singleBlogDate {
  margin: 0px 0px 16px 0px;
}

.singleBlogTitle {
  font-weight: 400;
  font-size: 28px;
  text-align: center;
}

.singleBlogTeaser {
  text-align: center;
}

.singleBlogLink {
  margin: 20px 0px 0px 0px;
  text-decoration: underline;
  color: #538d72;
}

@media (max-width: 899px) {
  .singleBlog {
    margin: 75px 50px;
  }
}
